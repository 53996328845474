<template>
  <div class="text-left">
    <BaseHeader :title="'Academic Levels'"></BaseHeader>

    <BaseLoading
      style="min-height: 400px; height: 90vh"
      v-if="loading"
    ></BaseLoading>

    <section v-else>
      <div class=" mt-2 ">
        <!-- <base-select-site
          class="p-2"
          @siteSelected="getAcademicLevels"
        ></base-select-site> -->
        <div class="card text-right d-flex align-items-center flex-row w-100 justify-content-between p-3" style>
          <v-text-field
            v-model="$store.state.search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="my-auto"
          ></v-text-field>
          <v-spacer></v-spacer>
          <button
            color="primary"
            class="my-auto btn btn-add  ml-2"
            size="small"
            @click.stop="crudAction"
            >New</button
          >
        </div>
        <!-- datatable -->
        <v-data-table
          :mobile-breakpoint="0"
          :headers="headers"
          :items="academicLevels"
          :search="$store.state.search"
        >
          <template v-slot:[`item.default`]="{ item }">
            <v-chip v-if="item.default !== '0'" small color="primary"
              >Default</v-chip
            >
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <v-chip :color="item.status ? 'primary' : 'error'" small outlined>{{
              item.status ? "Active" : "Inactive"
            }}</v-chip>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <!-- <router-link
                class="white--text v-btn v-btn--contained v-btn--fab v-btn--round theme--light v-size--x-small success"
                :to="{ name: 'ViewSystemClient', params: { id: item.id || '' }}"
              >
                <v-icon small class="white--text">mdi-eye</v-icon>
              </router-link> -->
            <v-btn
              elevation="0"
              class="white--text"
              @click="
                editMode(item);
                edit = true;
                $store.state.dialog = true;
              "
              fab
              x-small
            >
              <v-icon color="primary">mdi-pencil</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <!-- /datatable -->
      </div>

      <!-- clients modal -->
      <v-dialog
        v-model="$store.state.dialog"
        class="text-left"
        transition="dialog-top-transition"
        max-width="600"
      >
        <v-card :loading="academicLevelsForm.busy">
          <v-card-title class="text-h5">
            {{
              edit
                ? `Update ${academicLevelsForm.level || ""}`
                : "New Academic Level"
            }}
          </v-card-title>
          <v-card-text>
            <!--  form -->
            <form
              class="flex-fill text-left"
              ref="academicLevelsForm"
              @submit.prevent="createWriterLevel"
            >
              <div class>
                <div class="mb-3">
                  <span class="d-block text-muted"
                    >All fields are required</span
                  >
                </div>

                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      dense
                      outlined
                      label="Description"
                      name="description"
                      type="text"
                      v-model="academicLevelsForm.description"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="academicLevelsForm"
                            class="v-messages theme--light error--text"
                            field="description"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      dense
                      outlined
                      label="Amount"
                      name="amount"
                      min="0"
                      v-model="academicLevelsForm.amount"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="academicLevelsForm"
                            class="v-messages theme--light error--text"
                            field="amount"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-switch
                      v-model="academicLevelsForm.default"
                      label="Is Default"
                      color="primary"
                      class="mt-0"
                      hide-details
                    ></v-switch>
                  </v-col>

                  <v-col cols="12" md="4" v-show="edit">
                    <v-switch
                      v-model="academicLevelsForm.status"
                      label="Is Active"
                      color="primary"
                      class="mt-0"
                      hide-details
                    ></v-switch>
                  </v-col>
                </v-row>

                <v-btn-toggle>
                  <v-btn
                    :disabled="academicLevelsForm.busy"
                    type="submit"
                    color="primary"
                    class="white--text"
                    >{{ edit ? "Update" : "Save" }}</v-btn
                  >
                  <v-btn
                    type="button"
                    @click="$store.state.dialog = !$store.state.dialog"
                    >Close</v-btn
                  >
                </v-btn-toggle>
              </div>
            </form>

            <!-- / form -->
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- clients modal -->
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "AcademicLevels",
  computed: {
    ...mapState("academiclevels", [
      "academicLevelsForm",
      "loading",
      "academicLevels",
      "headers",
    ]),
    // ...mapState("clients", ["clients"]),
  },
  data() {
    return {
      countries: [],
      edit: false,
    };
  },

  methods: {
    ...mapActions("academiclevels", ["getAcademicLevels"]),
    // ...mapActions("clients", ["getClients"]),
    createWriterLevel() {
      this.academicLevelsForm.default = this.academicLevelsForm.default
        ? true
        : false;
      this.academicLevelsForm.status = this.academicLevelsForm.status
        ? true
        : false;
      this.edit
        ? this.academicLevelsForm
            .put(
              `${this.$baseUrl}/Setups/AcademicLevels/${this.$store.state.site_id}`
            )
            .then((res) => {
              this.$store.state.dialog = false;
              this.$notify({
                title: "Success",
                text: `style updated successifully.`,
                style: "success",
              });
              this.getAcademicLevels();
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
              this.$notify({
                title: "Error",
                text: err.response.data
                  ? err.response.data.Message
                  : `Error while updating style`,
                style: "danger",
              });
            })
        : this.academicLevelsForm
            .post(
              `${this.$baseUrl}/Setups/AcademicLevels/${this.$store.state.site_id}`
            )
            .then((res) => {
              this.$store.state.dialog = false;
              this.$notify({
                title: "Success",
                text: `style created successifully.`,
                style: "success",
              });
              this.getAcademicLevels();
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
              this.$notify({
                title: "Error",
                text: err.response.data
                  ? err.response.data.Message
                  : `Error while creating style`,
                style: "danger",
              });
            });
    },

    editMode(style) {
      this.edit = true;
      this.academicLevelsForm.reset();
      this.$store.state.dialog = true;
      Object.assign(this.academicLevelsForm, { ...style });
      this.academicLevelsForm.default =
        this.academicLevelsForm.default !== "0" ? true : false;
      this.academicLevelsForm.active =
        this.academicLevelsForm.active !== "0" ? true : false;
    },

    crudAction() {
      if (this.$store.state.site_id) {
        this.edit = false;
        this.academicLevelsForm.reset();
        this.$store.state.dialog = true;
      } else {
        this.$notify({
          title: "Error",
          text: "Please select a site to proceed",
          style: "danger",
        });
      }
    },
  },
  async mounted() {
    this.$store.state.site_id = this.$route.params.siteid

    if (this.$store.state.site_id) {
      await this.getAcademicLevels();
    }
  },
};
</script>
